











// Core
import { Component, Vue } from 'vue-property-decorator'

// Styles
import './home.scss'

@Component({
  components: {
    'v-project-team': () => import('@/components/ProjectTeam.vue'),
    'v-task-list': () => import('@/components/TaskList.vue'),
  },
})
export default class Home extends Vue {}
